<template>
<div class="container">
	<div>
		<p class="strong" style="text-align:center">叁零荟酒友会合伙人规则</p>
		<p style="text-align: center;">（2023年11月24）</p>
		<p class="strong">1、合伙人加盟：</p>
		<p>a)加盟保证金。会员有意兼/专职创业，主动推广和经营三零荟酒品，可通过小程序申请/由会长邀请加盟成为合伙人。合伙人加盟时缴纳2万元加盟保证金，满2年后平台无条件全额无息退还。</p>
		<p>b)加盟礼包。合伙人加盟时平台赠送2万元大礼包：(4 箱良师益友+2个鉴酒杯)。</p>
		<p>c)达标礼包。入伙第1年考核推广指标（转发小程序/二维码，发展直接会员满100名达标），第2年考核业绩指标（业绩满5万元达标）。第3年起，合伙人业绩每年达标，平台每年赠送1万元小礼包：(2箱良师益友+1个鉴酒杯)。。</p>
		<p class="strong">2、合伙人权利义务：</p>
		<p>a)享有业绩奖励。</p>
		<p>i.业绩内容。合伙人业绩包括合伙人自己消费金额、合伙人发展的两级会员（直接会员、次接会员）消费金额、团购消费金额。</p>
		<p>ii.奖励比例。平台按产消费业绩的一定比例给予合伙人推广奖励，具体标准为：风华正茂款酱酒15%、志同道合款酱酒为20%、良师益友款酱酒为30%。其他产品的奖励标准，上架后可在小程序“合伙人模块”查询。推广奖励按月结算，自动计入合伙人积分。</p>
		<p>iii.业绩标准。合伙人业绩标准为5万元/年。达标则次年继续保持合伙人身份；未达标则次年视为自动退伙，保留会员资格，会员等级根据本人累计消费金额确定。合伙人升任会长后，不再考核业绩。</p>
		<p>b)钻石级会员待遇。合伙人自己消费，一律享受钻石级会员价。合伙人享有普通会员的两级积分奖励。</p>
		<p>c)团购权。合伙人应建立至少1个团购微信群，合伙人自任团长，负责团购群内动销、宣传和互动。该合伙人的团购群二维码信息，将在小程序上展示给该合伙人发展的各级会员；同时团长可将本群团购信息链接转发朋友圈和微信好友。团购活动信息（产品、数量、价格、有效期等），由平台统一预告（短信、公众号、小程序等）。任何人参与该团购的消费金额，均计入该合伙人业绩。</p>
		<p>d)平台分红权和投资权。合伙人享有总部销售平台公司的分红权和购股优先权，按合伙人业绩占比计算份额，统一通过会长代为行使。细则另行发布。</p>
		<p class="strong">3、酒友会会长：</p>
		<p>a)会长资格。会长必须具备合伙人身份，平台鼓励合伙人升级为会长。</p>
		<p>b)分会设立。合伙人发展下属会员成为新合伙人，人数满5人（含自己）时，可申请设立分会，自任分会会长。平台给予新会长管理补贴，按该分会总业绩（全体合伙人总业绩）的3%计算。补贴按月结算，自动计入新会长积分。</p>
		<p>c)分会裂变。鼓励分会裂变设立新的分会。原分会的合伙人设立新分会/升为新会长的，平台给予原会长拓展补贴，按新分会各合伙人总业绩的2%计算。补贴按月结算，自动计入原会长积分。</p>
		<p>d)分会撤销。分会合伙人人数低于5人连续满2年，该分会自动撤销，其合伙人由平台统一托管。会长降为合伙人，会长管理补贴(3%)取消；会长降为合伙人或普通会员，会长拓展补贴(2%)均永久保留。</p>
		<p>e)会长品鉴酒。对于合伙人满5人、10人、20人的分会、总会，平台分别赠送会长1个10斤、20斤、50斤、100斤叁零荟陶瓷酒坛，平台每年免费配送相应容量的“志同道合”款品鉴酒，用于会长日常品鉴招待。</p>
		<p class="strong">4、本规则为《叁零荟用户注册和服务协议》的附件，为其不可分割的组成部分；本规则未尽事宜，适用《叁零荟用户注册和服务协议》条款的约定。</p>
		<p style="text-align: right; padding-top: 20px;">上海叁個零科技有限公司</p>
		<p style="text-align: right;">2023年11月24日</p>
	</div>
</div>
</template>

<script>
	
	export default {
	  name: 'index',
	  components: {
	  },
	  data() {
		return {
		  title:"三个零",
		}
	  },
	  created() {
		
	  },
	  methods: {

	  } 
	}
</script>

<style>
	p{
		margin: 5px 0;
		line-height: 1.5;
	}
	.container {
	  padding: 10px;
	}
	.strong{
		font-weight: bold;
	}
	.item{
		margin-top: 10px;
	}
</style>
